<template>
  <div class="dg-module-form form-section mb-0" v-if="amReady">
    <div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>

      <div class="form-section">
        <div class="sub-title">Basic Information</div>
        <el-row class="mt-2" :gutter="10">

          <el-col :class="$langConfig.cssClass" style="width: 260px;">
            <dg-select-field
                :control="control"
                :editable="editable"
                :field_span="13"
                :label_span="11"
                :options="typesDropdown"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Insurance Type"
                name="category_id"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 400px;margin-left: 30px">
            <dg-text-field
                :control="control"
                :editable="editable"
                :field_span="15"
                :label_span="9"
                :translateLabel='false'
                field_mark="required"
                label="Insurance Company"
                name="company_name"
                placeholder=""
                rules="required"></dg-text-field>
          </el-col>
        </el-row>

        <el-row class="mt-3">
          <el-col :class="$langConfig.cssClass" style="width: 250px;">
            <dg-text-field
                :control="control"
                :editable="editable"
                :field_span="13"
                :label_span="11"
                :translateLabel='false'
                field_mark="optional"
                label="Contact Person"
                name="contact_person"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 300px; margin-left: 30px">
            <phone-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="true" :with-extension="true" label="Phone #" labelWidth="70px;"></phone-number>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 200px;">
            <phone-number labelWidth="40px" :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :with-extension="false" label="Fax" phoneAreaPrefixName="contact_fax_area_code" phoneNumberName="contact_fax" phoneNumberExtName="contact_fax_ext"></phone-number>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 30px;">
            <dg-text-field
                :control="control"
                :editable="editable"
                :field_span="19"
                :label_span="5"
                :translateLabel='false'
                field_mark="optional"
                label="Email"
                name="email"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>
        </el-row>

        <el-row class="mt-3">
          <el-col :class="$langConfig.cssClass" style="width: 300px;">
            <dg-text-field
                :control="control"
                :editable="editable"
                :field_span="19"
                :label_span="5"
                :translateLabel='false'
                field_mark="optional"
                label="Policy#"
                name="policy_number"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 30px;">
            <dg-date-picker-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="13"
                :label_span="11"
                :translateLabel='false'
                field_mark="optional"
                format="DD/MM/yyyy"
                label="Starting Date"
                name="starting_date"
                placeholder="dd/mm/yyyy"
                rules=""
                type="date"
                valueFormat="yyyy-MM-dd"></dg-date-picker-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 30px;">
            <dg-date-picker-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="13"
                :label_span="11"
                :translateLabel='false'
                field_mark="optional"
                format="DD/MM/yyyy"
                label="Expiry Date"
                name="expiry_date"
                placeholder="dd/mm/yyyy"
                rules=""
                type="date"
                valueFormat="yyyy-MM-dd"></dg-date-picker-field>
          </el-col>
        </el-row>

        <el-row class="mt-3">
          <el-col :class="$langConfig.cssClass" style="width: 200px;">
            <dg-select-field
                :control="control"
                :editable="editable"
                :field_span="12"
                :label_span="12"
                :options="feesPeriodDropdown"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="optional"
                label="Fees Schedule"
                name="fees_period_id"
                placeholder=""
                rules=""></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 30px;" >
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="14"
                :label_span="10"
                :translateLabel='false'
                :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
                @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
                field_mark="optional"
                maxLength="12"
                label="Amount ($)"
                name="amount"
                placeholder="e.g 12,000"
                rules=""></dg-text-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 400px;margin-left: 30px;">
            <dg-text-field
                :control="control"
                :editable="editable"
                :field_span="18"
                :label_span="6"
                :translateLabel='false'
                field_mark="optional"
                label="Comments"
                name="comments"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>
        </el-row>

        <el-row class="mt-3">
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="24"
                :label_span="24"
                :translateLabel='false'
                field_mark=""
                label=""
                inputPrepend="Insurance Description"
                name="notes"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>
        </el-row>
      </div>

    <el-row :gutter="10">
      <el-col :span="24" class="mt-2 border-top">
        <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
          <label class="error-field-validate-message text-capitalize"
                 v-show="!this.validData">{{ (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria') }}</label>
        </el-col>
        <el-col :span="12" class="pt-3 text-right">
          <dg-confirm-button
              :loading="requestOn"
              :onConfirm="moduleDeleteAction"
              :translate="false"
              style="margin-right: 10px"
              confirmButtonType="danger"
              fieldSize="mini"
              icon="el-icon-delete"
              popIcon="el-icon-delete"
              text="remove"
              title="sure to removing this record ?"
              type="danger"
              v-if="editMode"></dg-confirm-button>

          <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                     type="warning" v-if="editable && editMode"></dg-button>
          <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                     v-if="!editable && editMode"></dg-button>

          <dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                     :translate="false"
                     :type="actionButtonType" fieldSize="mini"
                     v-if="editable"></dg-button>

          <!--<dg-confirm-button
              :icon="actionButtonIcon"
              :loading="requestOn"
              :onConfirm="createModule"
              :text="actionButtonLabel"
              :translate="false"
              :type="actionButtonType"
              fieldSize="mini"
              v-if="editable"></dg-confirm-button>-->
        </el-col>
      </el-col>
    </el-row>
    </div>
</template>

<script>
import module from './../index.js'
import TaxReportingPeriods from '@/json/dropdown/TaxReportingPeriods.json'
export default {
  beforeMount() {this.getFormData()},
  data() {
    return {
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
      typesDropdown: [
        {
          label: 'Car',
          value: 1
        },
        {
          label: 'Residential',
          value: 2
        },
        {
          label: 'Commercial',
          value: 3
        }
      ],
      feesPeriodDropdown: TaxReportingPeriods,
    }
  },
  components: {
    'phone-number': () => import('@/components/phone-number/View'),
  },
  methods: {
    getFormData() {
      let promises = []

      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    ...$mapActions('Office-Insurance-api', {
      getEntityDropDownList: 'getDropDownList',
      /*
      updateFile: 'updateFile',
      updateGallery: 'updateGallery',
      removeGalleryImage: 'removeGalleryImage'*/
    }),
    /* removeFromGallery (file) {
      let params = {
        id: this.dataToBind.id,
        fileId: file.id,
        parentId: file.parent_id
      }
      this.removeGalleryImage(params).then(r => {
          if (this.onEditCallback) {
            this.onEditCallback(r.data, 'gallery', false)
          }
          $services['notify']['success']('Picture Removed')
      })
    },
    async uploadFile (entityId, fieldName, apiName) {
      if (this.dataModel.hasOwnProperty(fieldName) && this.dataModel[fieldName]) {
        let data =  {}
        data[fieldName] = this.dataModel[fieldName]

        let fileParams = {data, id: entityId}
        await this[apiName](fileParams).then(r => {
          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')
        }).catch(error => {
          $services['notify']['danger']('File Not Uploaded')
        })
      }
    },
    async uploadGallery (entityId) {
      if (this.dataModel.hasOwnProperty('gallery') && this.dataModel.gallery) {
        let galleryParams = {
          data: {
            gallery: this.dataModel.gallery
          },
          id: entityId
        }

        await this.updateGallery(galleryParams).then(r => {
          $services['notify']['success']('Gallery Uploaded')
        }).catch(error => {
          $services['notify']['danger']('Gallery Not Uploaded')
        })
      }
    },*/
    async createModule() {
      this.validateFields().then( async result => {
        if (!result) { this.requestErrorMessage = null; return}

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;


        /* Images Handleing - removing files they are handled alone
        delete apiParams.data.file
        delete apiParams.data.picture
        delete apiParams.data.gallery

        if (apiMethod == 'update') {
            await this.uploadFile(this.dataToBind.id, 'file', 'updateFile') // Updating File
            await this.uploadFile(this.dataToBind.id, 'picture', 'updateFile') // Updating Picture
            await this.uploadGallery(this.dataToBind.id) // Updating Gallery
        }*/

        $store.dispatch('Office-Insurance-api/' + apiMethod, apiParams).then(async response => {
          let entity = response.data
          this.amReady = false

          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')
          /* if (apiMethod == 'create') {
             await this.uploadFile(entity.id, 'file', 'updateFile') // Updating File
             await this.uploadFile(entity.id, 'picture', 'updateFile') // Updating Picture
             await this.uploadGallery(entity.id) // Updating Gallery
          } */

          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {this.requestOn = false})
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
